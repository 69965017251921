import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../state/slices/authSlice";
import Loader from "../../components/common/loaders/Loader";
import Message from "../../components/common/Message";
import Modal from "../../components/common/Modal";
import ModalHeader from "../../components/common/ModalHeader";
import ModalBody from "../../components/common/ModalBody";
import ModalFooter from "../../components/common/ModalFooter";
import { useUpdatePalengkeMutation } from "../../state/slices/palengkesApiSlice";
import { formatIsoDate } from "../../utils/utils";
import { toast } from "react-toastify";

const PalengkeDetails = ({ palengke, isLoading, error, onSubmit }) => {
  const [enterName, setEnteredName] = useState("");
  const [enteredAddress, setEnteredAddress] = useState("");
  const { accessToken } = useSelector(selectUserInfo);
  const closeModalRef = useRef();

  const { id, name, address, createdBy, createdAt } = palengke || {};

  const [updatePalengke, { isLoading: isLoadingUpdate }] =
    useUpdatePalengkeMutation();

  useEffect(() => {
    setEnteredName(name);
    setEnteredAddress(address);
  }, [palengke]);

  const _submitForm = async () => {
    if (enterName === "") {
      toast.error("Please enter name");
      return;
    }

    if (enteredAddress === "") {
      toast.error("Please enter address");
      return;
    }

    try {
      const updatePalengkeFields = {
        id,
        name: enterName,
        address: enteredAddress,
      };

      await updatePalengke({
        palengke: updatePalengkeFields,
        accessToken,
      }).unwrap();

      onSubmit();

      closeModalRef.current.click();

      toast.success("Palengke updated successfully");
    } catch (e) {
      console.log(e);
      if (e.data.errors) {
        toast.error(e.data.errors[0].message);
      } else if (e.data.message) {
        toast.error(e.data.message);
      } else {
        toast.error("Something went wrong, please try again.");
      }
    }
  };

  return (
    <>
      <div className='box box-solid'>
        <div className='box-header with-border'>
          <h3 className='box-title'>Palengke Details &mdash; {id}</h3>
        </div>
        <div className='box-body '>
          {isLoading && (
            <Loader
              title='Loading...'
              size={50}
              center
            />
          )}

          {error && (
            <Message variant='danger'>
              {error.data.message || error.error}
            </Message>
          )}

          {!isLoading && !error && (
            <table className='table no-margin'>
              <tbody>
                <tr>
                  <th
                    style={{
                      width: "50%",
                    }}
                    className='no-border-top'
                  >
                    Name:
                  </th>
                  <td className='no-border-top'>{name}</td>
                </tr>

                <tr>
                  <th>Address:</th>
                  <td>{address}</td>
                </tr>

                <tr>
                  <th>Created By:</th>
                  <td>
                    {createdBy.firstName} {createdBy.lastName}
                  </td>
                </tr>

                <tr>
                  <th>Date Created:</th>
                  <td>{formatIsoDate(createdAt)}</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        <div className='box-footer'>
          <button
            className='btn btn-primary btn-sm'
            data-toggle='modal'
            data-target='#update-palengke'
            disabled={isLoadingUpdate}
          >
            Update Palengke
          </button>
        </div>
      </div>
      <Modal id='update-palengke'>
        <ModalHeader title={`Update ${name}`} />
        <ModalBody>
          <form>
            <div className='form-group'>
              <label htmlFor='name'>Name</label>

              <input
                type='text'
                name='name'
                id='name'
                className='form-control'
                value={enterName}
                onChange={e => setEnteredName(e.target.value)}
              />
            </div>

            <div className='form-group'>
              <label htmlFor='address'>Address</label>

              <input
                type='text'
                name='address'
                id='address'
                className='form-control'
                value={enteredAddress}
                onChange={e => setEnteredAddress(e.target.value)}
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <button
            type='button'
            className='btn btn-success '
            onClick={e => _submitForm(e)}
            disabled={isLoadingUpdate}
          >
            Submit
          </button>

          <button
            type='button'
            className='btn btn-danger pull-right'
            data-dismiss='modal'
            ref={closeModalRef}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PalengkeDetails;
