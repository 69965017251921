import React from "react";
import { Link } from "react-router-dom";
import { formatIsoDate } from "../../utils/utils";
import Loader from "../../components/common/loaders/Loader";
import { useGetUserRideRequestsQuery } from "../../state/slices/usersApiSlice";
import Message from "../../components/common/Message";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../state/slices/authSlice";

const UserRideHistory = ({ userId }) => {
  const { accessToken } = useSelector(selectUserInfo);

  const {
    data: rideRequests,
    isLoading,
    error,
  } = useGetUserRideRequestsQuery({ userId, accessToken });

  return (
    <div className='box box-solid'>
      <div className='box-header with-border'>
        <h3 className='box-title'>
          <i className='fa fa-history'></i> Ride History
        </h3>
      </div>
      <div className='box-body table-responsive no-padding'>
        {isLoading && (
          <Loader
            title='Loading...'
            size={50}
            center
          />
        )}

        {error && (
          <Message
            variant='danger'
            message={
              error?.data?.message || error?.error || "Something went wrong"
            }
          />
        )}

        {!isLoading && !error && (
          <table className='table table-hover'>
            <tbody>
              <tr>
                <th>ID</th>
                <th>Origin Location</th>
                <th>Destination Location</th>
                <th>Total Passenger</th>
                <th>Status</th>
                <th>Passenger Name</th>
                <th>Driver Name</th>
                <th>Car Details</th>
                <th>Date Requested</th>
              </tr>

              {rideRequests.map(rideRequest => {
                const {
                  rideRequestId,
                  originLocationDetails,
                  destinationLocationDetails,
                  status,
                  dateRequested,
                  totalPassenger,
                  driverCarDetails,
                  passengerUserDetails, // this is good
                  driverUserDetails, // this errors
                } = rideRequest;

                console.log(rideRequest);
                return (
                  <tr key={rideRequestId}>
                    <td>
                      <Link to={`/ride-requests/${rideRequestId}`}>
                        {rideRequestId}
                      </Link>
                    </td>
                    <td>{originLocationDetails.address}</td>
                    <td>{destinationLocationDetails.address}</td>
                    <td>{totalPassenger}</td>
                    <td>{status || "waiting"}</td>
                    <td>
                      <Link to={`/users/${passengerUserDetails?.id}`}>
                        {passengerUserDetails?.firstName}{" "}
                        {passengerUserDetails?.lastName}
                      </Link>
                    </td>
                    <td>
                      <Link to={`/users/${driverUserDetails?.id}`}>
                        {driverUserDetails?.firstName}{" "}
                        {driverUserDetails?.lastName}
                      </Link>
                    </td>
                    <td>
                      {driverCarDetails?.color} {driverCarDetails?.model}{" "}
                      {driverCarDetails?.year}
                    </td>
                    <td>{formatIsoDate(dateRequested)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default UserRideHistory;
