import React from "react";
import ContentHeader from "../components/layouts/ContentHeader";
import MainContent from "../components/layouts/MainContent";
import Loader from "../components/common/loaders/Loader";
import { useGetTodasQuery } from "../state/slices/todasApiSlice";
import Message from "../components/common/Message";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../state/slices/authSlice";
import { formatIsoDate } from "../utils/utils";

const TodasPage = () => {
  const { accessToken } = useSelector(selectUserInfo);
  const { data: todas, isLoading, error } = useGetTodasQuery({ accessToken });

  return (
    <>
      <ContentHeader pageTitle='TODAs'>
        <ol className='breadcrumb'>
          <li>
            <div>
              <i className='fa fa-list'></i> TODAs
            </div>
          </li>
        </ol>
      </ContentHeader>

      <MainContent>
        <div className='box box-solid'>
          <div className='box-body'>
            <Link
              to='/todas/add'
              className='btn btn-success btn-sm'
            >
              <i className='fa fa-plus'></i> Add TODA
            </Link>
          </div>
        </div>

        <div className='box box-solid'>
          <div className='box-header'>
            <div className='box-tools'>
              <div
                className='input-group input-group-sm hidden-xs'
                style={{ width: "150px" }}
              >
                <input
                  type='text'
                  name='table_search'
                  className='form-control pull-right'
                  placeholder='Search'
                />

                <div className='input-group-btn'>
                  <button
                    type='submit'
                    className='btn btn-default'
                  >
                    <i className='fa fa-search'></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='box-body table-responsive no-padding'>
            {isLoading && (
              <Loader
                title='Loading...'
                size={50}
                center
              />
            )}

            {error && (
              <Message
                title='Error'
                variant='danger'
              >
                {error.data.message || error.error}
              </Message>
            )}

            {!isLoading && !error && (
              <table className='table table-hover'>
                <tbody>
                  <tr>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Address</th>
                    <th>Date Registered</th>
                  </tr>
                  {todas.map(toda => (
                    <tr key={toda.id}>
                      <td>
                        <Link to={`/todas/${toda.id}`}>{toda.title}</Link>
                      </td>
                      <td>{toda.description}</td>
                      <td>{toda.locationDetails?.address}</td>
                      <td>{formatIsoDate(toda.createdAt)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          <div className='box-footer clearfix'>
            <ul className='pagination pagination-sm no-margin pull-right'>
              <li>
                <a href='#'>«</a>
              </li>
              <li>
                <a href='#'>1</a>
              </li>
              <li>
                <a href='#'>2</a>
              </li>
              <li>
                <a href='#'>3</a>
              </li>
              <li>
                <a href='#'>»</a>
              </li>
            </ul>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default TodasPage;
