import { useState } from "react";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../state/slices/authSlice";

import { useCreateUserMutation } from "../state/slices/usersApiSlice";
import { useGetAllProvincesQuery } from "../state/slices/provinceApiSlice";
import { useGetAllMunicipalitiesByProvinceIdQuery } from "../state/slices/municipalityApiSlice";
import { useGetAllBarangaysByProvinceIdAndMunicipalityIdQuery } from "../state/slices/barangayApiSlice";

import MainContent from "../components/layouts/MainContent";
import ContentHeader from "../components/layouts/ContentHeader";
import { Link, useNavigate } from "react-router-dom";
import { capitalizeWords } from "../utils/utils";

import { toast } from "react-toastify";

const CreateUserPage = () => {
  const { id, accessToken } = useSelector(selectUserInfo);

  const [createUser, { isLoading }] = useCreateUserMutation();

  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState("");
  const [provinceId, setProvinceId] = useState("");
  const [municipalityId, setMunicipalityId] = useState("");
  const [barangayId, setBarangayId] = useState("");
  const [addressLine1, setAddressLine1] = useState("");

  const {
    data: provinces,
    isLoading: isLoadingProvinces,
    error: errorProvinces,
  } = useGetAllProvincesQuery({ accessToken });

  const {
    data: municipalities,
    isLoading: isLoadingMunicipalities,
    error: errorMunicipalities,
  } = useGetAllMunicipalitiesByProvinceIdQuery(
    { provinceId, accessToken },
    {
      skip: !provinceId,
    },
  );

  const {
    data: barangays,
    isLoading: isLoadingBarangays,
    error: errorBarangays,
  } = useGetAllBarangaysByProvinceIdAndMunicipalityIdQuery(
    { provinceId, municipalityId, accessToken },
    {
      skip: !provinceId || !municipalityId,
    },
  );

  const handleSubmit = async e => {
    e.preventDefault();

    const capitalizedFirstName = capitalizeWords(firstName);
    const capitalizedLastName = capitalizeWords(lastName);

    try {
      await createUser({
        data: {
          firstName: capitalizedFirstName,
          lastName: capitalizedLastName,
          email,
          phone,
          type,
          provinceId,
          municipalityId,
          barangayId,
          addressLine1,
          createdBy: id,
        },
        accessToken,
      }).unwrap();

      navigate("/users");

      toast.success(
        `User ${capitalizedFirstName} ${capitalizedLastName} is created.`,
      );
    } catch (e) {
      console.log(e);

      if (e.data.errors) {
        toast.error(e.data.errors[0].message);
      } else if (e.data.message) {
        toast.error(e.data.message);
      } else {
        toast.error("Something went wrong, please try again.");
      }
    }
  };

  return (
    <>
      <ContentHeader pageTitle='Create User'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/users'>
              <i className='fa fa-users'></i> Users
            </Link>
          </li>

          <li>
            <Link>
              <i className='fa fa-plus'></i> Create User
            </Link>
          </li>
        </ol>
      </ContentHeader>

      <MainContent>
        <div className='row'>
          <div className='col-md-6'>
            <div className='box box-solid'>
              <div className='box-body'>
                <form onSubmit={handleSubmit}>
                  <div className='form-group'>
                    <label htmlFor='type'>Account Type</label>

                    <select
                      type='text'
                      className='form-control'
                      id='type'
                      required
                      value={type}
                      onChange={e => setType(e.target.value)}
                    >
                      <option value=''>Select type</option>
                      <option value='shopper'>Shopper</option>
                      <option value='dispatcher'>Dispatcher</option>
                    </select>
                  </div>

                  <div className='form-group'>
                    <label htmlFor='firstName'>First Name</label>

                    <input
                      type='text'
                      className='form-control'
                      id='firstName'
                      placeholder='Enter first name'
                      required
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='lastName'>Last Name</label>

                    <input
                      type='text'
                      className='form-control'
                      id='lastName'
                      placeholder='Enter last name'
                      required
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='email'>Email</label>

                    <input
                      type='email'
                      className='form-control'
                      id='email'
                      placeholder='Enter email'
                      required
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='phone'>
                      Phone <small className='text-muted'>Ex. 9238964687</small>
                    </label>

                    <input
                      type='text'
                      className='form-control'
                      id='phone'
                      placeholder='Enter user phone'
                      required
                      value={phone}
                      onChange={e => setPhone(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='provinceId'>Province</label>

                    <select
                      id='provinceId'
                      className='form-control'
                      value={provinceId}
                      required
                      onChange={e => {
                        setProvinceId(e.target.value);
                        setMunicipalityId("");
                        setBarangayId("");
                      }}
                    >
                      <option value=''>Select Province</option>
                      {!isLoadingProvinces &&
                        !errorProvinces &&
                        provinces.map(province => (
                          <option
                            key={province.id}
                            value={province.id}
                          >
                            {province.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className='form-group'>
                    <label htmlFor='municipalityId'>Municipality</label>

                    <select
                      id='municipalityId'
                      className='form-control'
                      value={municipalityId}
                      required
                      onChange={e => {
                        setMunicipalityId(e.target.value);
                        setBarangayId("");
                      }}
                    >
                      <option value=''>Select Municipality</option>
                      {provinceId &&
                        !isLoadingMunicipalities &&
                        !errorMunicipalities &&
                        municipalities.map(municipality => (
                          <option
                            key={municipality.id}
                            value={municipality.id}
                          >
                            {municipality.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className='form-group'>
                    <label htmlFor='barangayId'>Barangay</label>

                    <select
                      id='barangayId'
                      className='form-control'
                      value={barangayId}
                      required
                      onChange={e => setBarangayId(e.target.value)}
                    >
                      <option value=''>Select Barangay</option>
                      {provinceId &&
                        municipalityId &&
                        !isLoadingBarangays &&
                        !errorBarangays &&
                        barangays.map(barangay => (
                          <option
                            key={barangay.id}
                            value={barangay.id}
                          >
                            {barangay.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className='form-group'>
                    <label htmlFor='addressLine1'>Address</label>

                    <input
                      type='text'
                      className='form-control'
                      id='addressLine1'
                      placeholder='Enter address'
                      required
                      value={addressLine1}
                      onChange={e => setAddressLine1(e.target.value)}
                    />
                  </div>

                  <button
                    type='submit'
                    className='btn btn-success'
                    disabled={isLoading}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default CreateUserPage;
