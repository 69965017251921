import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ContentHeader from "../components/layouts/ContentHeader";
import MainContent from "../components/layouts/MainContent";
import { useState } from "react";
import {
  useGetTodaByIdQuery,
  useUpdateTodaMutation,
} from "../state/slices/todasApiSlice";
import { useGetAllProvincesQuery } from "../state/slices/provinceApiSlice";
import { useGetAllMunicipalitiesByProvinceIdQuery } from "../state/slices/municipalityApiSlice";
import { useGetAllBarangaysByProvinceIdAndMunicipalityIdQuery } from "../state/slices/barangayApiSlice";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../state/slices/authSlice";
import Message from "../components/common/Message";
import Loader from "../components/common/loaders/Loader";
import NewLocationMap from "../components/common/NewLocationMap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const UpdateTodaPage = () => {
  const { id: todaId } = useParams();

  const { accessToken } = useSelector(selectUserInfo);
  const navigate = useNavigate();

  const { data, isLoading, error } = useGetTodaByIdQuery({
    todaId,
    accessToken,
  });

  const [updateToda, { isLoading: isSubmitting }] = useUpdateTodaMutation();

  // entry data
  const {
    locationDetails,
    title,
    description,
    provinceId,
    municipalityId,
    barangayId,
  } = data || {};

  const { latitude, longitude, address } = locationDetails || {};

  // form state
  const [enteredTitle, setEnteredTitle] = useState("");
  const [enteredDescription, setEnteredDescription] = useState("");
  const [enteredAddress, setEnteredAddress] = useState("");
  const [enteredLatitude, setEnteredLatitude] = useState("");
  const [enteredLongitude, setEnteredLongitude] = useState("");
  const [enteredProvinceId, setEnteredProvinceId] = useState("");
  const [enteredMunicipalityId, setEnteredMunicipalityId] = useState("");
  const [enteredBarangayId, setEnteredBarangayId] = useState("");

  const {
    data: provinces,
    isLoading: isLoadingProvinces,
    error: errorProvinces,
  } = useGetAllProvincesQuery({ accessToken });

  const {
    data: municipalities,
    isLoading: isLoadingMunicipalities,
    error: errorMunicipalities,
  } = useGetAllMunicipalitiesByProvinceIdQuery(
    { provinceId: enteredProvinceId, accessToken },
    {
      skip: !enteredProvinceId,
    },
  );

  const {
    data: barangays,
    isLoading: isLoadingBarangays,
    error: errorBarangays,
  } = useGetAllBarangaysByProvinceIdAndMunicipalityIdQuery(
    {
      provinceId: enteredProvinceId,
      municipalityId: enteredMunicipalityId,
      accessToken,
    },
    {
      skip: !enteredProvinceId || !enteredMunicipalityId,
    },
  );

  useEffect(() => {
    if (data) {
      setEnteredTitle(title || "");
      setEnteredDescription(description || "");
      setEnteredAddress(address || "");
      setEnteredLatitude(latitude || "");
      setEnteredLongitude(longitude || "");
      setEnteredProvinceId(provinceId || "");
      setEnteredMunicipalityId(municipalityId || "");
      setEnteredBarangayId(barangayId || "");
    }
  }, [data, title, description, address]);

  const submitForm = async e => {
    e.preventDefault();

    if (!latitude || !longitude) {
      toast.error("TODA location is required");
      return;
    }

    try {
      const { id } = await updateToda({
        data: {
          id: todaId,
          title: enteredTitle,
          description: enteredDescription,
          address: enteredAddress,
          latitude: enteredLatitude,
          longitude: enteredLongitude,
          provinceId: provinceId,
          municipalityId: municipalityId,
          barangayId: barangayId,
        },
        accessToken,
      }).unwrap();

      toast.success(`TODA ${title} is updated.`);

      navigate(`/todas/${id}`);
    } catch (e) {
      console.log(e);

      if (e.data.errors) {
        toast.error(e.data.errors[0].message);
      } else if (e.data.message) {
        toast.error(e.data.message);
      } else {
        toast.error("Something went wrong, please try again.");
      }
    }
  };

  const selectedCoordinates = latLng => {
    const { lat, lng } = latLng;

    setEnteredLatitude(lat);
    setEnteredLongitude(lng);
  };

  return (
    <>
      <ContentHeader pageTitle='Update TODA'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/todas'>
              <i className='fa fa-list'></i> TODAs
            </Link>
          </li>
          <li>
            <Link to={`/todas/${todaId}`}>
              <i className='fa fa-info'></i> {title}
            </Link>
          </li>
          <li>
            <Link>
              <i className='fa fa-edit'></i> Update TODA
            </Link>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        <div className='row'>
          <div className='col-md-4'>
            <div className='box box-solid'>
              <div className='box-body'>
                {isLoading && (
                  <Loader
                    title='Loading...'
                    size={50}
                    center
                  />
                )}

                {error && (
                  <Message>{error?.message || "Something went wrong"}</Message>
                )}

                {!isLoading && (
                  <form onSubmit={submitForm}>
                    <div className='form-group'>
                      <label htmlFor='title'>Title</label>

                      <input
                        type='text'
                        className='form-control'
                        id='title'
                        placeholder='Enter title'
                        required
                        value={enteredTitle}
                        onChange={e => setEnteredTitle(e.target.value)}
                      />
                    </div>

                    <div className='form-group'>
                      <label htmlFor='description'>Description</label>

                      <textarea
                        id='description'
                        className='form-control'
                        placeholder='Enter short description'
                        value={enteredDescription}
                        onChange={e => setEnteredDescription(e.target.value)}
                      />
                    </div>

                    <div className='form-group'>
                      <label htmlFor='provinceId'>Province</label>

                      <select
                        id='provinceId'
                        className='form-control'
                        value={enteredProvinceId}
                        required
                        onChange={e => {
                          setEnteredProvinceId(e.target.value);
                          setEnteredMunicipalityId("");
                          setEnteredMunicipalityId("");
                        }}
                      >
                        <option value=''>Select Province</option>
                        {!isLoadingProvinces &&
                          !errorProvinces &&
                          provinces.map(province => {
                            return (
                              <option
                                key={province.id}
                                value={province.id}
                              >
                                {province.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>

                    <div className='form-group'>
                      <label htmlFor='municipalityId'>Municipality</label>

                      <select
                        id='municipalityId'
                        className='form-control'
                        value={enteredMunicipalityId}
                        required
                        onChange={e => {
                          setEnteredMunicipalityId(e.target.value);
                          setEnteredBarangayId("");
                        }}
                      >
                        <option value=''>Select Municipality</option>
                        {enteredProvinceId &&
                          !isLoadingMunicipalities &&
                          !errorMunicipalities &&
                          municipalities.map(municipality => (
                            <option
                              key={municipality.id}
                              value={municipality.id}
                            >
                              {municipality.name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className='form-group'>
                      <label htmlFor='barangayId'>Barangay</label>

                      <select
                        id='barangayId'
                        className='form-control'
                        value={enteredBarangayId}
                        required
                        onChange={e => setEnteredBarangayId(e.target.value)}
                      >
                        <option value=''>Select Barangay</option>
                        {enteredProvinceId &&
                          enteredMunicipalityId &&
                          !isLoadingBarangays &&
                          !errorBarangays &&
                          barangays.map(barangay => (
                            <option
                              key={barangay.id}
                              value={barangay.id}
                            >
                              {barangay.name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className='form-group'>
                      <label htmlFor='address'>Address</label>

                      <input
                        type='text'
                        className='form-control'
                        id='address'
                        placeholder='Enter address'
                        required
                        value={enteredAddress}
                        onChange={e => setEnteredAddress(e.target.value)}
                      />
                    </div>

                    <div className='form-group'>
                      <label htmlFor='address'>Set Map Location</label>

                      <input
                        type='text'
                        className='form-control'
                        id='address'
                        placeholder='Please point the location on the map'
                        required
                        value={`${enteredLatitude},${enteredLongitude}`}
                        readOnly
                      />
                    </div>

                    <button
                      type='submit'
                      className='btn btn-primary'
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>

          <div className='col-md-8'>
            <NewLocationMap
              autocomplete
              latitude={latitude}
              longitude={longitude}
              onSelectedCoordinates={selectedCoordinates}
            />
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default UpdateTodaPage;
