import { BARANGAYS_URL } from "../../constants/constants";

import { apiSlice } from "./apiSlice";

export const barangayApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    countAllBarangays: builder.query({
      query: ({ accessToken }) => {
        return {
          url: `${BARANGAYS_URL}/count`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      keepUnusedDataFor: 60,
    }),
    getAllBarangays: builder.query({
      query: ({ accessToken }) => {
        return {
          url: `${BARANGAYS_URL}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      keepUnusedDataFor: 60,
    }),
    getAllBarangaysByMunicipalityId: builder.query({
      query: ({ municipalityId, accessToken }) => ({
        url: `${BARANGAYS_URL}/municipality/${municipalityId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 60,
    }),
    getAllBarangaysByProvinceIdAndMunicipalityId: builder.query({
      query: ({ provinceId, municipalityId, accessToken }) => ({
        url: `${BARANGAYS_URL}/province/${provinceId}/municipality/${municipalityId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 60,
    }),
    getBarangayById: builder.query({
      query: ({ barangayId, accessToken }) => ({
        url: `${BARANGAYS_URL}/${barangayId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 60,
    }),
    createBarangay: builder.mutation({
      query: ({ data, accessToken }) => ({
        url: `${BARANGAYS_URL}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: data,
      }),
    }),
    updateBarangay: builder.mutation({
      query: ({ updatedFields, accessToken }) => ({
        url: `${BARANGAYS_URL}/${updatedFields.id}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: updatedFields,
      }),
    }),
  }),
});

export const {
  useCountAllBarangaysQuery,
  useGetAllBarangaysQuery,
  useGetAllBarangaysByMunicipalityIdQuery,
  useGetAllBarangaysByProvinceIdAndMunicipalityIdQuery,
  useGetBarangayByIdQuery,
  useCreateBarangayMutation,
  useUpdateBarangayMutation,
} = barangayApiSlice;
