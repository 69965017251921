import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";
import MainContent from "../../components/layouts/MainContent";
import ContentHeader from "../../components/layouts/ContentHeader";
import WalletDetails from "../../components/common/WalletDetails";

import { selectUserInfo } from "../../state/slices/authSlice";
import { useGetBalanceRequestByIdQuery } from "../../state/slices/balanceRequestsApiSlice";
import BalanceRequestDetails from "./BalanceRequestDetails";
import Loader from "../../components/common/loaders/Loader";
import Message from "../../components/common/Message";

const BalanceRequestDetailsPage = () => {
  const { id } = useParams();
  const { accessToken } = useSelector(selectUserInfo);

  const {
    data: balanceRequest,
    isLoading,
    error,
    refetch,
  } = useGetBalanceRequestByIdQuery({
    balanceRequestId: id,
    accessToken,
  });

  return (
    <>
      <ContentHeader pageTitle='Balance Requests'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/balance-requests'>
              <i className='fa fa-list'></i> Balance Requests
            </Link>
          </li>
          <li>
            <Link to='#'>
              <i className='fa fa-info'></i> Balance Request Details
            </Link>
          </li>
        </ol>
      </ContentHeader>

      <MainContent>
        <div className='row'>
          {isLoading && (
            <Loader
              title='Loading...'
              center
              size={50}
            />
          )}

          {error && (
            <div className='col-md-12'>
              <Message
                variant='danger'
                title='Error'
                message={
                  error?.data?.message ||
                  error?.message ||
                  "Something went wrong."
                }
              />
            </div>
          )}

          {!isLoading && !error && (
            <>
              <div className='col-md-6'>
                <BalanceRequestDetails
                  balanceRequest={balanceRequest}
                  onSubmit={() => refetch()}
                />
              </div>

              <div className='col-md-6'>
                <WalletDetails walletId={balanceRequest.walletId} />
              </div>
            </>
          )}
        </div>
      </MainContent>
    </>
  );
};

export default BalanceRequestDetailsPage;
