import ContentHeader from "../components/layouts/ContentHeader";
import MainContent from "../components/layouts/MainContent";
import Loader from "../components/common/loaders/Loader";
import Message from "../components/common/Message";
import { useGetShopperGroupsQuery } from "../state/slices/shopperGroupsApiSlice";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../state/slices/authSlice";
import { formatIsoDate } from "../utils/utils";

const ShopperGroupsPage = () => {
  const { accessToken } = useSelector(selectUserInfo);
  const { data, isLoading, error } = useGetShopperGroupsQuery({ accessToken });

  const shopperGroups = data || [];

  return (
    <>
      <ContentHeader pageTitle='Shopper Groups'>
        <ol className='breadcrumb'>
          <li>
            <a href='#'>
              <i className='fa fa-list'></i> Shopper Groups
            </a>
          </li>
        </ol>
      </ContentHeader>

      <MainContent>
        <div className='box box-solid'>
          <div className='box-body '>
            <Link
              to='/shopper-groups/add'
              className='btn btn-success btn-sm'
            >
              <i className='fa fa-plus'></i> Add Shopper Group
            </Link>
          </div>
        </div>

        <div className='box box-solid'>
          <div className='box-header'>
            <div className='box-tools'>
              <div
                className='input-group input-group-sm hidden-xs'
                style={{ width: "150px" }}
              >
                <input
                  type='text'
                  name='table_search'
                  className='form-control pull-right'
                  placeholder='Search'
                />

                <div className='input-group-btn'>
                  <button
                    type='submit'
                    className='btn btn-default'
                  >
                    <i className='fa fa-search'></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='box-body table-responsive no-padding'>
            {isLoading && (
              <Loader
                title='Loading...'
                size={50}
                center
              />
            )}

            {error && (
              <Message
                title='Error'
                variant='danger'
              >
                {error.data.message || error.error}
              </Message>
            )}

            {!isLoading && !error && (
              <table className='table table-hover'>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Store</th>
                    <th>Type</th>
                    <th>Date Registered</th>
                  </tr>
                  {shopperGroups.length === 0 ? (
                    <tr>
                      <td
                        colSpan='4'
                        align='center'
                      >
                        <strong>NO RECORDS FOUND.</strong>
                      </td>
                    </tr>
                  ) : (
                    shopperGroups.map(shopperGroup => {
                      const {
                        id,
                        name,
                        storeOrPalengkeId,
                        type,
                        createdAt,
                        updatedAt,
                      } = shopperGroup;

                      return (
                        <tr key={id}>
                          <td>
                            <Link to={`/shopper-groups/${id}`}>{name}</Link>
                          </td>
                          <td>{storeOrPalengkeId}</td>
                          <td>{type}</td>
                          <td>{formatIsoDate(createdAt)}</td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default ShopperGroupsPage;
