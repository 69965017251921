import React from "react";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../state/slices/authSlice";
import { useGetAllStoresQuery } from "../state/slices/storesApiSlice";

import ContentHeader from "../components/layouts/ContentHeader";
import MainContent from "../components/layouts/MainContent";
import Loader from "../components/common/loaders/Loader";
import Message from "../components/common/Message";
import { Link } from "react-router-dom";

import { formatIsoDate } from "../utils/utils";

const StoresPage = () => {
  const { accessToken } = useSelector(selectUserInfo);

  const {
    data: stores,
    isLoading,
    error,
  } = useGetAllStoresQuery({ accessToken });

  return (
    <>
      <ContentHeader pageTitle='Stores'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/stores'>
              <i className='fa fa-list'></i> Stores
            </Link>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        <div className='box box-solid'>
          <div className='box-body table-responsive no-padding'>
            {isLoading && (
              <Loader
                title='Loading...'
                size={50}
                center
              />
            )}

            {error && (
              <Message
                variant='danger'
                title='Error'
                message={
                  error?.data?.message || error?.error || "Something went wrong"
                }
              />
            )}

            {!isLoading && !error && (
              <table className='table table-hover'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Created At</th>
                  </tr>
                </thead>

                <tbody>
                  {stores.length === 0 ? (
                    <tr>
                      <td
                        colSpan='3'
                        align='center'
                      >
                        <strong>NO RECORDS FOUND.</strong>
                      </td>
                    </tr>
                  ) : (
                    stores.map(store => (
                      <tr key={store.id}>
                        <td>
                          <Link to={`/stores/${store.id}`}>{store.name}</Link>
                        </td>
                        <td>{store.isApproved ? "Approved" : "Pending"}</td>
                        <td>{formatIsoDate(store.createdAt)}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default StoresPage;
