import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import ContentHeader from "../components/layouts/ContentHeader";
import MainContent from "../components/layouts/MainContent";
import Message from "../components/common/Message";
import { useState } from "react";
import { useCreateShopperGroupMutation } from "../state/slices/shopperGroupsApiSlice";
import { useGetAllStoresQuery } from "../state/slices/storesApiSlice";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../state/slices/authSlice";
import Select from "react-select";
import { toast } from "react-toastify";

const CreateShopperGroupPage = () => {
  const { id, accessToken } = useSelector(selectUserInfo);
  const navigate = useNavigate();

  const {
    data: stores,
    isLoading: isLoadingStores,
    error: errorStores,
  } = useGetAllStoresQuery({ accessToken });

  const [name, setName] = useState("");
  const [storeId, setStoreId] = useState("");
  const [type, setType] = useState("single");
  const [accountNumber, setAccountNumber] = useState("");

  const [createShopperGroup, { isLoading }] = useCreateShopperGroupMutation();

  const submitForm = async e => {
    e.preventDefault();

    try {
      const { id: shopperGroupId } = await createShopperGroup({
        name,
        storeId,
        type,
        accountNumber,
        createdBy: id,
        accessToken,
      }).unwrap();

      toast.success(`Shopper Group ${name} is created.`);

      navigate(`/shopper-groups/${shopperGroupId}`);
    } catch (e) {
      console.log(e);
      if (e.data.errors) {
        toast.error(e.data.errors[0].message);
      } else if (e.data.message) {
        toast.error(e.data.message);
      } else {
        toast.error("Something went wrong, please try again.");
      }
    }
  };

  const storeOptions = useMemo(() => {
    if (!stores || stores.length === 0) return [];

    return stores.map(store => ({
      value: store.id,
      label: store.name,
    }));
  }, [stores]);

  return (
    <>
      <ContentHeader pageTitle='Create Shopper Group'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/shopper-groups'>
              <i className='fa fa-list'></i> Shopper Groups
            </Link>
          </li>
          <li>
            <Link>
              <i className='fa fa-plus'></i> Create Shopper Group
            </Link>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        {errorStores && (
          <Message variant='danger'>
            {errorStores?.data?.message || errorStores?.error}
          </Message>
        )}
        <div className='box box-solid'>
          <div className='box-body'>
            <form
              role='form'
              onSubmit={submitForm}
            >
              <div className='form-group'>
                <label htmlFor='name'>Name</label>

                <input
                  type='text'
                  className='form-control'
                  id='name'
                  placeholder='Enter name'
                  required
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </div>

              <div className='form-group'>
                <label htmlFor='accountNumber'>
                  Account Number{" "}
                  <small className='text-muted'>
                    (Phone number ex. 9238964659)
                  </small>
                </label>

                <input
                  type='text'
                  className='form-control'
                  id='accountNumber'
                  placeholder='Enter account number'
                  required
                  value={accountNumber}
                  onChange={e => setAccountNumber(e.target.value)}
                />
              </div>

              <div className='form-group'>
                <label htmlFor='storeId'>Store ID</label>

                <Select
                  id='storeId'
                  options={storeOptions}
                  value={
                    storeId
                      ? storeOptions.find(option => option.value === storeId)
                      : null
                  }
                  onChange={store => {
                    setStoreId(store ? store.value : null);
                  }}
                  placeholder='Select store'
                  isClearable
                  required
                />
              </div>

              <div className='form-group'>
                <label htmlFor='type'>Type</label>

                <select
                  className='form-control'
                  name='type'
                  id='type'
                  required
                  value={type}
                  onChange={e => setType(e.target.value)}
                >
                  <option value='single'>Single</option>
                  <option value='multiple'>Multiple</option>
                </select>
              </div>

              <button
                type='submit'
                className='btn btn-primary'
                disabled={isLoading}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default CreateShopperGroupPage;
