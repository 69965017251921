import { TODAS_URL } from "../../constants/constants";

import { apiSlice } from "./apiSlice";

export const todasApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTodas: builder.query({
      query: ({ accessToken }) => ({
        url: TODAS_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getTodaById: builder.query({
      query: ({ todaId, accessToken }) => ({
        url: `${TODAS_URL}/${todaId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 60,
    }),
    createToda: builder.mutation({
      query: ({ data, accessToken }) => ({
        url: `${TODAS_URL}/`,
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    updateToda: builder.mutation({
      query: ({ data, accessToken }) => ({
        url: `${TODAS_URL}/${data.id}`,
        method: "PUT",
        body: data,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
  }),
});

export const {
  useGetTodaByIdQuery,
  useGetTodasQuery,
  useCreateTodaMutation,
  useUpdateTodaMutation,
} = todasApiSlice;
