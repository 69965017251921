import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AccountStatus from "../components/common/AccountStatus";
import AccountType from "../components/common/AccountType";
import Loader from "../components/common/loaders/Loader";
import Message from "../components/common/Message";
import ContentHeader from "../components/layouts/ContentHeader";
import MainContent from "../components/layouts/MainContent";
import { selectUserInfo } from "../state/slices/authSlice";
import { useGetUsersQuery } from "../state/slices/usersApiSlice";
import { formatIsoDate } from "../utils/utils";

const UsersPage = () => {
  const { accessToken } = useSelector(selectUserInfo);

  const { data: users, isLoading, error } = useGetUsersQuery({ accessToken });

  return (
    <>
      <ContentHeader pageTitle='Users'>
        <ol className='breadcrumb'>
          <li>
            <div>
              <i className='fa fa-users'></i> Users
            </div>
          </li>
        </ol>
      </ContentHeader>

      <MainContent>
        <div className='box box-solid'>
          <div className='box-body'>
            <Link
              to='/users/create-user'
              className='btn btn-success btn-sm'
            >
              <i className='fa fa-plus'></i> Create User
            </Link>
          </div>
        </div>

        <div className='box box-solid'>
          <div className='box-header'>
            <div className='box-tools'>
              <div
                className='input-group input-group-sm hidden-xs'
                style={{ width: "150px" }}
              >
                <input
                  type='text'
                  name='table_search'
                  className='form-control pull-right'
                  placeholder='Search'
                />

                <div className='input-group-btn'>
                  <button
                    type='submit'
                    className='btn btn-default'
                  >
                    <i className='fa fa-search'></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='box-body table-responsive no-padding'>
            {isLoading && (
              <Loader
                title='Loading...'
                size={50}
                center
              />
            )}

            {error && (
              <Message
                title='Error'
                variant='danger'
              >
                {error.data.message || error.error}
              </Message>
            )}

            {!isLoading && !error && (
              <table className='table table-hover'>
                <tbody>
                  <tr>
                    <th>User</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Account Type</th>
                    <th>Account Status</th>
                    <th>Date Registered</th>
                  </tr>
                  {users.map(user => (
                    <tr key={user.id}>
                      <td>
                        <Link to={`/users/${user.id}`}>
                          {user.firstName} {user.lastName}
                        </Link>
                      </td>
                      <td>{user.email}</td>
                      <td>+63{user.phone}</td>
                      <td>
                        <AccountType type={user.type} />
                      </td>
                      <td>
                        <AccountStatus accountStatus={user.isVerified} />
                      </td>
                      <td>{formatIsoDate(user.createdAt)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          <div className='box-footer clearfix'>
            <ul className='pagination pagination-sm no-margin pull-right'>
              <li>
                <Link to='#'>«</Link>
              </li>
              <li>
                <Link to='#'>1</Link>
              </li>
              <li>
                <Link to='#'>2</Link>
              </li>
              <li>
                <Link to='#'>3</Link>
              </li>
              <li>
                <Link to='#'>»</Link>
              </li>
            </ul>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default UsersPage;
