import React, { useEffect } from "react";
import MainWrapper from "./MainWrapper";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ContentWrapper from "./ContentWrapper";
import ControlSidebar from "./ControlSidebar";
import Footer from "./Footer";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { auth } from "../../config/firebase";
import { setAccessToken } from "../../state/slices/authSlice";

const AppLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const checkAndRefreshToken = async () => {
    try {
      auth.currentUser
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          dispatch(setAccessToken(idToken));
        })
        .catch(function (error) {
          console.error("Error refreshing ID token:", error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      checkAndRefreshToken();
    }, 500);
  }, [dispatch, location, auth]);

  return (
    <MainWrapper>
      <Header />
      <Sidebar />
      <ContentWrapper>
        <Outlet />
      </ContentWrapper>
      <ControlSidebar />
      <Footer />
    </MainWrapper>
  );
};

export default AppLayout;
