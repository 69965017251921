import React from "react";
import PuffLoader from "react-spinners/PuffLoader";

const InlineCircleLoader = ({
  color,
  size,
  title,
  center,
  speedMultiplier,
}) => {
  return (
    <span
      style={
        center && {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 16,
          flexDirection: "column",
        }
      }
    >
      <PuffLoader
        color={color}
        size={size}
        aria-label='Loading...'
        data-testid='loader'
        speedMultiplier={speedMultiplier}
      />

      {title && (
        <p
          style={{
            fontSize: "1.75rem",
            marginTop: "1rem",
            fontStyle: "italic",
          }}
        >
          {" "}
          {title}
        </p>
      )}
    </span>
  );
};

export default InlineCircleLoader;
