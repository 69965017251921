import React from "react";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../state/slices/authSlice";
import { useGetAllBarangaysQuery } from "../state/slices/barangayApiSlice";

import ContentHeader from "../components/layouts/ContentHeader";
import MainContent from "../components/layouts/MainContent";
import Loader from "../components/common/loaders/Loader";
import Message from "../components/common/Message";
import { Link } from "react-router-dom";

import { formatIsoDate } from "../utils/utils";

const BarangaysPage = () => {
  const { accessToken } = useSelector(selectUserInfo);

  const {
    data: barangays,
    isLoading,
    error,
  } = useGetAllBarangaysQuery({ accessToken });

  return (
    <>
      <ContentHeader pageTitle='Barangays'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/location-management'>
              <i className='fa fa-map-pin'></i> Location Management
            </Link>
          </li>
          <li className='active'>
            <Link>
              <i className='fa fa-list '></i> Barangays
            </Link>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        <div className='box box-solid'>
          <div className='box-body table-responsive no-padding'>
            {isLoading && (
              <Loader
                title='Loading...'
                size={50}
                center
              />
            )}

            {error && (
              <Message
                variant='danger'
                title='Error'
                message={
                  error?.data?.message || error?.error || "Something went wrong"
                }
              />
            )}

            {!isLoading && !error && (
              <table className='table table-hover'>
                <thead>
                  <tr>
                    <th>Barangay</th>
                    <th>Created At</th>
                  </tr>
                </thead>

                <tbody>
                  {barangays.length === 0 ? (
                    <tr>
                      <td colSpan='2'>No barangays found.</td>
                    </tr>
                  ) : (
                    barangays.map(barangay => (
                      <tr key={barangay.id}>
                        <td>
                          <Link to={`/barangays/${barangay.id}`}>
                            {barangay.name}
                          </Link>
                        </td>
                        <td>{formatIsoDate(barangay.createdAt)}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default BarangaysPage;
