import Message from "../../components/common/Message";
import Loader from "../../components/common/loaders/Loader";
import { useGetTodaMembersByIdQuery } from "../../state/slices/todaMembersApiSlice";
import { useSelector } from "react-redux";
import { formatIsoDate } from "../../utils/utils";
import { selectUserInfo } from "../../state/slices/authSlice";
import { Link } from "react-router-dom";

const TodaMembers = ({ todaId }) => {
  const { accessToken } = useSelector(selectUserInfo);

  const {
    data: todaMembers,
    isLoading,
    error,
  } = useGetTodaMembersByIdQuery({ todaId, accessToken });

  return (
    <div className='box box-solid'>
      <div className='box-header'>
        <h3 className='box-title'>
          <i className='fa fa-users'></i> TODA Members
        </h3>

        <div className='box-tools'>
          <Link
            to={`/todas/${todaId}/manage-members`}
            className='btn btn-md btn-success'
          >
            <i className='fa fa-edit'></i> Manage members
          </Link>
        </div>
      </div>
      <div className='box-body table-responsive no-padding'>
        {isLoading && (
          <Loader
            title='Loading...'
            size={50}
            center
          />
        )}

        {error && (
          <Message
            title='Error'
            variant='danger'
          >
            {error?.data?.message || error?.error || "Something went wrong."}
          </Message>
        )}

        {!isLoading && !error && (
          <>
            <table className='table table-hover'>
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>Role</th>
                  <th>Added Date</th>
                </tr>

                {todaMembers.length === 0 ? (
                  <tr>
                    <td
                      colSpan={5}
                      align='center'
                    >
                      <strong>NO MEMBERS FOUND.</strong>
                    </td>
                  </tr>
                ) : (
                  todaMembers.map(member => {
                    const { userDetails, role, createdAt } = member;

                    return (
                      <tr key={userDetails?.id}>
                        <td>
                          {userDetails?.firstName} {userDetails?.lastName}
                        </td>
                        <td>+63{userDetails?.phone}</td>
                        <td>{role.toUpperCase()}</td>
                        <td>{formatIsoDate(createdAt)}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

export default TodaMembers;
