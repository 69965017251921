import { useSelector } from "react-redux";
import { selectUserInfo } from "../../state/slices/authSlice";
import { useGetBalanceRequestsQuery } from "../../state/slices/balanceRequestsApiSlice";

import { Link } from "react-router-dom";
import ContentHeader from "../../components/layouts/ContentHeader";
import MainContent from "../../components/layouts/MainContent";
import Loader from "../../components/common/loaders/Loader";
import Message from "../../components/common/Message";

import { formatCurrency, formatIsoDate } from "../../utils/utils";
import BalanceRequestType from "../../components/common/BalanceRequestType";

const BalanceRequestsPage = () => {
  const { accessToken } = useSelector(selectUserInfo);

  const {
    data: balanceRequests,
    isLoading,
    error,
  } = useGetBalanceRequestsQuery({ accessToken });

  return (
    <>
      <ContentHeader pageTitle='Balance Requests'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/balance-requests'>
              <i className='fa fa-list'></i> Balance Requests
            </Link>
          </li>
        </ol>
      </ContentHeader>

      <MainContent>
        <div className='box box-solid'>
          <div className='box-body table-responsive no-padding'>
            {isLoading && (
              <Loader
                title='Loading...'
                size={50}
                center
              />
            )}

            {error && (
              <Message
                variant='danger'
                title='Error'
                message={
                  error?.data?.message || error?.error || "Something went wrong"
                }
              />
            )}

            {!isLoading && !error && (
              <table className='table table-hover'>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>Note</th>
                    <th>Status</th>
                    <th>Requested By</th>
                  </tr>
                </thead>

                <tbody>
                  {balanceRequests.length === 0 ? (
                    <tr>
                      <td colSpan='6'>No balance requests found.</td>
                    </tr>
                  ) : (
                    balanceRequests.map(balanceRequest => {
                      const {
                        id,
                        amount,
                        type,
                        note,
                        status,
                        requestedBy,
                        createdAt,
                      } = balanceRequest;

                      return (
                        <tr>
                          <td>
                            <Link to={`/balance-requests/${id}`}>
                              {formatIsoDate(createdAt)}
                            </Link>
                          </td>
                          <td>{formatCurrency(amount)}</td>
                          <td>{type.toUpperCase()}</td>
                          <td>{note}</td>
                          <td>
                            <BalanceRequestType value={status} />
                          </td>
                          <td>
                            <Link to={`/users/${requestedBy.id}`}>
                              {requestedBy.firstName} {requestedBy.lastName}
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default BalanceRequestsPage;
