import { STORES_URL } from "../../constants/constants";
import { apiSlice } from "./apiSlice";

export const storesApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    countStores: builder.query({
      query: ({ accessToken }) => {
        return {
          url: `${STORES_URL}/count`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      keepUnusedDataFor: 60,
    }),
    getAllStores: builder.query({
      query: ({ accessToken }) => {
        return {
          url: `${STORES_URL}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      keepUnusedDataFor: 60,
    }),
    getStoreById: builder.query({
      query: ({ storeId, accessToken }) => {
        return {
          url: `${STORES_URL}/${storeId}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      keepUnusedDataFor: 60,
    }),
    activateStore: builder.mutation({
      query: ({ storeId, adminId, accessToken }) => {
        return {
          url: `${STORES_URL}/${storeId}/activate`,
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: {
            approvedBy: adminId,
          },
        };
      },
    }),
  }),
});

export const {
  useGetAllStoresQuery,
  useCountStoresQuery,
  useGetStoreByIdQuery,
  useActivateStoreMutation,
} = storesApiSlice;
