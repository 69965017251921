import { MUNICIPALITY_URL } from "../../constants/constants";

import { apiSlice } from "./apiSlice";

export const municipalityApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    countAllMunicipalities: builder.query({
      query: ({ accessToken }) => {
        return {
          url: `${MUNICIPALITY_URL}/count`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      keepUnusedDataFor: 60,
    }),
    getAllMunicipalities: builder.query({
      query: ({ accessToken }) => {
        return {
          url: `${MUNICIPALITY_URL}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      keepUnusedDataFor: 60,
    }),
    getAllMunicipalitiesByProvinceId: builder.query({
      query: ({ provinceId, accessToken }) => ({
        url: `${MUNICIPALITY_URL}/province/${provinceId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 60,
    }),
    getMunicipalityById: builder.query({
      query: ({ municipalityId, accessToken }) => ({
        url: `${MUNICIPALITY_URL}/${municipalityId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 60,
    }),
    createMunicipality: builder.mutation({
      query: ({ data, accessToken }) => ({
        url: `${MUNICIPALITY_URL}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: data,
      }),
    }),
    updateMunicipality: builder.mutation({
      query: ({ updatedFields, accessToken }) => ({
        url: `${MUNICIPALITY_URL}/${updatedFields.id}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: updatedFields,
      }),
    }),
  }),
});

export const {
  useCountAllMunicipalitiesQuery,
  useGetAllMunicipalitiesQuery,
  useGetAllMunicipalitiesByProvinceIdQuery,
  useGetMunicipalityByIdQuery,
  useCreateMunicipalityMutation,
  useUpdateMunicipalityMutation,
} = municipalityApiSlice;
