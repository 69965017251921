import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectUserInfo } from "../state/slices/authSlice";
import { useCreatePalengkeMutation } from "../state/slices/palengkesApiSlice";
import { useGetAllProvincesQuery } from "../state/slices/provinceApiSlice";
import { useGetAllMunicipalitiesByProvinceIdQuery } from "../state/slices/municipalityApiSlice";
import { useGetAllBarangaysByProvinceIdAndMunicipalityIdQuery } from "../state/slices/barangayApiSlice";

import ContentHeader from "../components/layouts/ContentHeader";
import MainContent from "../components/layouts/MainContent";

import { toast } from "react-toastify";
import NewLocationMap from "../components/common/NewLocationMap";

const CreatePalengkePage = () => {
  const { id, firstName, lastName, phone, accessToken } =
    useSelector(selectUserInfo);
  const navigate = useNavigate();
  const [createPalengke, { isLoading: isSubmitting }] =
    useCreatePalengkeMutation();

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [provinceId, setProvinceId] = useState("");
  const [municipalityId, setMunicipalityId] = useState("");
  const [barangayId, setBarangayId] = useState("");

  const {
    data: provinces,
    isLoading: isLoadingProvinces,
    error: errorProvinces,
  } = useGetAllProvincesQuery({ accessToken });

  const {
    data: municipalities,
    isLoading: isLoadingMunicipalities,
    error: errorMunicipalities,
  } = useGetAllMunicipalitiesByProvinceIdQuery(
    { provinceId, accessToken },
    {
      skip: !provinceId,
    },
  );

  const {
    data: barangays,
    isLoading: isLoadingBarangays,
    error: errorBarangays,
  } = useGetAllBarangaysByProvinceIdAndMunicipalityIdQuery(
    { provinceId, municipalityId, accessToken },
    {
      skip: !provinceId || !municipalityId,
    },
  );

  const setCoordinates = latLng => {
    const { lat, lng } = latLng;

    setLatitude(lat);
    setLongitude(lng);
  };

  const _submitForm = async e => {
    e.preventDefault();

    if (!latitude || !longitude) {
      toast.error("Palengke location is required");
      return;
    }

    const palengke = {
      name,
      address,
      latitude,
      longitude,
      provinceId,
      municipalityId,
      barangayId,
      createdBy: {
        id,
        firstName,
        lastName,
        phone,
      },
    };

    try {
      const { id: palengkeId } = await createPalengke({
        data: palengke,
        accessToken,
      }).unwrap();

      toast.success(`Palengke ${name} is created.`);

      navigate(`/palengkes/${palengkeId}`);
    } catch (e) {
      console.log(e);
      if (e.data.errors) {
        toast.error(e.data.errors[0].message);
      } else if (e.data.message) {
        toast.error(e.data.message);
      } else {
        toast.error("Failed to create Palengke.");
      }
    }
  };

  return (
    <>
      <ContentHeader pageTitle='Create Palengke'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/palengkes'>
              <i className='fa fa-list'></i> Palengkes
            </Link>
          </li>
          <li>
            <Link>
              <i className='fa fa-plus'></i> Create Palengke
            </Link>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        <div className='row'>
          <div className='col-md-6'>
            <div className='box box-solid'>
              <div className='box-header with-border'>
                <h3 className='box-title'>Palengke Details</h3>
              </div>
              <div className='box-body'>
                <form
                  role='form'
                  onSubmit={_submitForm}
                >
                  <div className='form-group'>
                    <label htmlFor='name'>Name</label>

                    <input
                      type='text'
                      className='form-control'
                      id='name'
                      placeholder='Enter name'
                      required
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='provinceId'>Province</label>

                    <select
                      id='provinceId'
                      className='form-control'
                      value={provinceId}
                      required
                      onChange={e => {
                        setProvinceId(e.target.value);
                        setMunicipalityId("");
                        setBarangayId("");
                      }}
                    >
                      <option value=''>Select Province</option>
                      {!isLoadingProvinces &&
                        !errorProvinces &&
                        provinces.map(province => (
                          <option
                            key={province.id}
                            value={province.id}
                          >
                            {province.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className='form-group'>
                    <label htmlFor='municipalityId'>Municipality</label>

                    <select
                      id='municipalityId'
                      className='form-control'
                      value={municipalityId}
                      required
                      onChange={e => {
                        setMunicipalityId(e.target.value);
                        setBarangayId("");
                      }}
                    >
                      <option value=''>Select Municipality</option>
                      {provinceId &&
                        !isLoadingMunicipalities &&
                        !errorMunicipalities &&
                        municipalities.map(municipality => (
                          <option
                            key={municipality.id}
                            value={municipality.id}
                          >
                            {municipality.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className='form-group'>
                    <label htmlFor='barangayId'>Barangay</label>

                    <select
                      id='barangayId'
                      className='form-control'
                      value={barangayId}
                      required
                      onChange={e => setBarangayId(e.target.value)}
                    >
                      <option value=''>Select Barangay</option>
                      {provinceId &&
                        municipalityId &&
                        !isLoadingBarangays &&
                        !errorBarangays &&
                        barangays.map(barangay => (
                          <option
                            key={barangay.id}
                            value={barangay.id}
                          >
                            {barangay.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className='form-group'>
                    <label htmlFor='address'>Address</label>

                    <input
                      type='text'
                      className='form-control'
                      id='address'
                      placeholder='Enter address'
                      required
                      value={address}
                      onChange={e => setAddress(e.target.value)}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='address'>Set Map Location</label>

                    <input
                      type='text'
                      className='form-control'
                      id='address'
                      placeholder='Please point the location on the map'
                      required
                      value={`${latitude},${longitude}`}
                      readOnly
                    />
                  </div>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='box box-solid'>
              <div className='box-header with-border'>
                <h3 className='box-title'>Select Coordinates</h3>
              </div>
              <div className='box-body'>
                <NewLocationMap
                  autocomplete
                  onSelectedCoordinates={setCoordinates}
                />
              </div>
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default CreatePalengkePage;
