import { useSelector } from "react-redux";

import { formatIsoDate } from "../../utils/utils";
import { selectUserInfo } from "../../state/slices/authSlice";
import { useGetShopperGroupByIdQuery } from "../../state/slices/shopperGroupsApiSlice";

import { Link } from "react-router-dom";
import Message from "../../components/common/Message";
import Loader from "../../components/common/loaders/Loader";

const ShopperGroupDetails = ({ shopperGroupId }) => {
  const { accessToken } = useSelector(selectUserInfo);

  const { data, isLoading, error } = useGetShopperGroupByIdQuery({
    id: shopperGroupId,
    accessToken,
  });

  const { name, storeOrPalengkeData, type, createdBy, createdAt, updatedAt } =
    data || {};

  return (
    <div className='box box-solid'>
      <div className='box-body box-profile'>
        {isLoading && (
          <Loader
            title='Loading...'
            size={50}
            center
          />
        )}

        {error && (
          <Message variant='danger'>
            {error.data.message || error.error}
          </Message>
        )}

        {!isLoading && !error && (
          <>
            <h3 className='profile-username text-center'>{name}</h3>
            <ul className='list-group list-group-unbordered'>
              <li className='list-group-item'>
                <b>ID</b> <span className='pull-right'>{shopperGroupId}</span>
              </li>
              <li className='list-group-item'>
                <b>Name</b> <span className='pull-right'>{name}</span>
              </li>
              <li className='list-group-item'>
                <b>Assigned To</b>{" "}
                <span className='pull-right'>{storeOrPalengkeData.name}</span>
              </li>
              <li className='list-group-item'>
                <b>Type</b> <span className='pull-right'>{type}</span>
              </li>
              <li className='list-group-item'>
                <b>Created By</b>{" "}
                <span className='pull-right'>
                  {createdBy.firstName} {createdBy.lastName}
                </span>
              </li>
              <li className='list-group-item'>
                <b>Created At</b>{" "}
                <span className='pull-right'>{formatIsoDate(createdAt)}</span>
              </li>

              <li className='list-group-item'>
                <Link
                  to={`/shopper-groups/${shopperGroupId}/edit`}
                  className='btn btn-primary btn-md center-block'
                >
                  <i className='fa fa-edit'></i> Update Shopper Group
                </Link>
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default ShopperGroupDetails;
