import React from "react";
import { useSelector } from "react-redux";
import MainContent from "../components/layouts/MainContent";
import ContentHeader from "../components/layouts/ContentHeader";
import InfoBox from "../components/common/InfoBox";
import Loader from "../components/common/loaders/Loader";
import Message from "../components/common/Message";
import { selectUserInfo } from "../state/slices/authSlice";
import { useGetUsersQuery } from "../state/slices/usersApiSlice";
import { useGetTodasQuery } from "../state/slices/todasApiSlice";
import { useGetShopperGroupCountQuery } from "../state/slices/shopperGroupsApiSlice";

const HomePage = () => {
  const { accessToken } = useSelector(selectUserInfo);

  // users
  const { data: users, isLoading, error } = useGetUsersQuery({ accessToken });

  const customerCount =
    users && users.filter(user => user.type === "customer").length;

  const driversCount =
    users && users.filter(user => user.type === "driver").length;

  const dispatchers =
    users && users.filter(user => user.type === "dispatcher").length;

  const shoppers =
    users && users.filter(user => user.type === "shopper").length;

  const merchants =
    users && users.filter(user => user.type === "merchant").length;

  // todas
  const {
    data: todas,
    isLoading: isLoadingTodas,
    error: errorTodas,
  } = useGetTodasQuery({ accessToken });

  const todasCount = todas && todas.length;

  const {
    data: shopperGroupCount,
    isLoading: isLoadingShopperGroupCount,
    error: errorShopperGroupCount,
  } = useGetShopperGroupCountQuery({ accessToken });

  return (
    <>
      <ContentHeader pageTitle='Dashboard'>
        <ol className='breadcrumb'>
          <li>
            <div>
              <i className='fa fa-dashboard'></i> Dashboard
            </div>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        {error && <Message>{error?.data?.message}</Message>}

        {errorTodas && <Message>{errorTodas?.data?.message}</Message>}

        {errorShopperGroupCount && (
          <Message>{errorShopperGroupCount?.data?.message}</Message>
        )}

        <div className='row'>
          <div className='col-md-3 col-sm-6 col-xs-12'>
            <InfoBox
              iconClass='fa fa-user'
              title='Customers'
              color='aqua'
            >
              {isLoading ? <Loader /> : customerCount}
            </InfoBox>
          </div>

          <div className='col-md-3 col-sm-6 col-xs-12'>
            <InfoBox
              iconClass='fa fa-list'
              title='TODAs'
              color='aqua'
            >
              {isLoadingTodas ? <Loader /> : todasCount}
            </InfoBox>
          </div>

          <div className='col-md-3 col-sm-6 col-xs-12'>
            <InfoBox
              iconClass='fa fa-user'
              title='Dispatchers'
              color='aqua'
            >
              {isLoading ? <Loader /> : dispatchers}
            </InfoBox>
          </div>

          <div className='col-md-3 col-sm-6 col-xs-12'>
            <InfoBox
              iconClass='fa fa-user'
              title='Drivers'
              color='aqua'
            >
              {isLoading ? <Loader /> : driversCount}
            </InfoBox>
          </div>

          <div className='col-md-3 col-sm-6 col-xs-12'>
            <InfoBox
              iconClass='fa fa-user'
              title='Merchants'
              color='aqua'
            >
              {isLoading ? <Loader /> : merchants}
            </InfoBox>
          </div>

          <div className='col-md-3 col-sm-6 col-xs-12'>
            <InfoBox
              iconClass='fa fa-user'
              title='Shoppers'
              color='aqua'
            >
              {isLoading ? <Loader /> : shoppers}
            </InfoBox>
          </div>

          <div className='col-md-3 col-sm-6 col-xs-12'>
            <InfoBox
              iconClass='fa fa-list'
              title='Shopper Groups'
              color='aqua'
            >
              {isLoadingShopperGroupCount ? (
                <Loader />
              ) : (
                shopperGroupCount?.count || 0
              )}
            </InfoBox>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default HomePage;
