import React from "react";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../state/slices/authSlice";
import { useCountAllProvincesQuery } from "../state/slices/provinceApiSlice";
import { useCountAllMunicipalitiesQuery } from "../state/slices/municipalityApiSlice";
import { useCountAllBarangaysQuery } from "../state/slices/barangayApiSlice";

import ContentHeader from "../components/layouts/ContentHeader";
import MainContent from "../components/layouts/MainContent";
import InlineCircleLoader from "../components/common/loaders/InlineCircleLoader";

import Message from "../components/common/Message";
import { Link } from "react-router-dom";

import { formatIsoDate } from "../utils/utils";

const LocationManagement = () => {
  const { accessToken } = useSelector(selectUserInfo);

  const { data: provinceCount, isLoading: isLoadingProvinceCount } =
    useCountAllProvincesQuery({ accessToken });

  const { data: municipalityCount, isLoading: isLoadingMunicipalityCount } =
    useCountAllMunicipalitiesQuery({ accessToken });

  const { data: barangayCount, isLoading: isLoadingBarangayCount } =
    useCountAllBarangaysQuery({ accessToken });

  return (
    <>
      <ContentHeader pageTitle='Location Management'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/location-management'>
              <i className='fa fa-list'></i> Location Management
            </Link>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        <div className='box box-solid'>
          <div className='box-body'>
            <ul
              className='list-group'
              style={{
                maxWidth: "300px",
                marginBottom: 0,
              }}
            >
              <li className='list-group-item'>
                <Link to='/provinces'>
                  Provinces{" "}
                  {isLoadingProvinceCount ? (
                    <InlineCircleLoader size={20} />
                  ) : (
                    <span className='badge bg-blue'>
                      {provinceCount?.count || 0}
                    </span>
                  )}
                </Link>
              </li>
              <li className='list-group-item'>
                <Link to='/municipalities'>
                  Municipalities{" "}
                  {isLoadingMunicipalityCount ? (
                    <InlineCircleLoader size={20} />
                  ) : (
                    <span className='badge bg-blue'>
                      {municipalityCount?.count || 0}
                    </span>
                  )}
                </Link>
              </li>
              <li className='list-group-item'>
                <Link to='/barangays'>
                  Barangays{" "}
                  {isLoadingBarangayCount ? (
                    <InlineCircleLoader size={20} />
                  ) : (
                    <span className='badge bg-blue'>
                      {barangayCount?.count || 0}
                    </span>
                  )}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default LocationManagement;
