import Message from "../../components/common/Message";
import NewLocationMap from "../../components/common/NewLocationMap";
import Loader from "../../components/common/loaders/Loader";

const PalengkeDetailsMap = ({ palengke, isLoading, error }) => {
  return (
    <div className='box box-solid'>
      <div className='box-header with-border'>
        <h3 className='box-title'>Palengke Location</h3>
      </div>
      <div className='box-body'>
        {isLoading && (
          <Loader
            title='Loading...'
            size={50}
            center
          />
        )}

        {error && (
          <Message variant='danger'>
            {error.data.message || error.error}
          </Message>
        )}

        {!isLoading && !error && palengke !== undefined && (
          <NewLocationMap
            latitude={palengke.latitude}
            longitude={palengke.longitude}
          />
        )}
      </div>
    </div>
  );
};

export default PalengkeDetailsMap;
