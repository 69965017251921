import { PROVINCE_URL } from "../../constants/constants";

import { apiSlice } from "./apiSlice";

export const provinceApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    countAllProvinces: builder.query({
      query: ({ accessToken }) => {
        return {
          url: `${PROVINCE_URL}/count`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      keepUnusedDataFor: 60,
    }),
    getAllProvinces: builder.query({
      query: ({ accessToken }) => {
        return {
          url: `${PROVINCE_URL}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      keepUnusedDataFor: 60,
    }),
    getProvinceById: builder.query({
      query: ({ provinceId, accessToken }) => ({
        url: `${PROVINCE_URL}/${provinceId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      keepUnusedDataFor: 60,
    }),
    createProvince: builder.mutation({
      query: ({ data, accessToken }) => ({
        url: `${PROVINCE_URL}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: data,
      }),
    }),
    updateProvince: builder.mutation({
      query: ({ data, accessToken }) => ({
        url: `${PROVINCE_URL}/${data.id}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: data,
      }),
    }),
  }),
});

export const {
  useCountAllProvincesQuery,
  useGetAllProvincesQuery,
  useGetProvinceByIdQuery,
  useCreateProvinceMutation,
  useUpdateProvinceMutation,
} = provinceApiSlice;
