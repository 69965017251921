import React, { useEffect } from "react";
import ContentHeader from "../components/layouts/ContentHeader";
import MainContent from "../components/layouts/MainContent";
import { Link, useSearchParams } from "react-router-dom";
import { useGetTransactionsQuery } from "../state/slices/transactionsApiSlice";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../state/slices/authSlice";
import Loader from "../components/common/loaders/Loader";
import Message from "../components/common/Message";
import TransactionType from "../components/common/TransactionType";
import { formatCurrency, formatIsoDate } from "../utils/utils";

const TransactionsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const after = searchParams.get("after") || null;
  const before = searchParams.get("before") || null;
  const limit = searchParams.get("limit") || 10;
  const view = searchParams.get("view") || "createdAt";
  const order = searchParams.get("order") || "desc";

  useEffect(() => {
    const paramsToSet = { limit, view, order };

    if (after) {
      paramsToSet.after = after;
    }

    if (before) {
      paramsToSet.before = before;
    }

    setSearchParams(paramsToSet);
  }, [setSearchParams, limit, view, before, after, order]);

  const { accessToken } = useSelector(selectUserInfo);
  const { data, isLoading, error } = useGetTransactionsQuery({
    accessToken,
    after,
    before,
    limit,
    view,
    order,
  });

  const { transactions, pagination } = data || {};
  const { prev, next } = pagination || {};

  return (
    <>
      <ContentHeader pageTitle='Wallet Transactions'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/transactions'>
              <i className='fa fa-list'></i> Transactions
            </Link>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        <div className='box box-solid'>
          <div className='box-header'>
            <div className='box-tools'>
              <div
                className='input-group input-group-sm hidden-xs'
                style={{ width: "150px" }}
              >
                <input
                  type='text'
                  name='table_search'
                  className='form-control pull-right'
                  placeholder='Search'
                />

                <div className='input-group-btn'>
                  <button
                    type='submit'
                    className='btn btn-default'
                  >
                    <i className='fa fa-search'></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='box-body table-responsive no-padding'>
            {isLoading && (
              <Loader
                title='Loading...'
                size={50}
                center
              />
            )}

            {error && (
              <Message
                variant='danger'
                title='Error'
                message={
                  error.data.message || error.error || "Something went wrong."
                }
              />
            )}

            {!isLoading && !error && (
              <>
                <table className='table table-hover'>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>ID</th>
                      <th>Source</th>
                      <th>Transaction Type</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.length === 0 ? (
                      <tr>
                        <td
                          colSpan={5}
                          align='center'
                        >
                          <strong>NO RECORDS FOUND.</strong>
                        </td>
                      </tr>
                    ) : (
                      transactions.map(transaction => {
                        const {
                          transactionId,
                          amount,
                          createdAt,
                          source,
                          transactionType,
                        } = transaction;

                        return (
                          <tr key={transactionId}>
                            <td>{formatIsoDate(createdAt)}</td>
                            <td>
                              <Link to={`/transaction/${transactionId}`}>
                                {transactionId}
                              </Link>
                            </td>
                            <td>{source.toUpperCase()}</td>
                            <td>
                              <TransactionType value={transactionType} />
                            </td>
                            <td>
                              <strong>{formatCurrency(amount)}</strong>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </>
            )}
          </div>

          <div className='box-footer clearfix'>
            {!isLoading && !error && (
              <ul className='pagination pagination-lg no-margin pull-right'>
                <li>
                  <button
                    className='btn btn-md'
                    disabled={!prev}
                    role='link'
                    aria-disabled='true'
                    onClick={() => {
                      setSearchParams({ before: prev, limit });
                    }}
                  >
                    «
                  </button>
                </li>
                <li>
                  <button
                    className='btn btn-md btn-disabled'
                    disabled={!next}
                    onClick={() => {
                      setSearchParams({ after: next, limit });
                    }}
                  >
                    »
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default TransactionsPage;
