import { useSelector } from "react-redux";
import Loader from "../../components/common/loaders/Loader";
import NewLocationMap from "../../components/common/NewLocationMap";
import Message from "../../components/common/Message";
import { selectUserInfo } from "../../state/slices/authSlice";
import { useGetTodaByIdQuery } from "../../state/slices/todasApiSlice";
import { formatIsoDate } from "../../utils/utils";
import { Link } from "react-router-dom";

const TodaDetails = ({ todaId }) => {
  const { accessToken } = useSelector(selectUserInfo);

  const { data, isLoading, error } = useGetTodaByIdQuery({
    todaId,
    accessToken,
  });

  const {
    locationDetails,
    title,
    description,
    createdBy,
    createdAt,
    updatedAt,
  } = data || {};

  return (
    <>
      <div className='box box-solid'>
        <div className='box-body box-profile'>
          {isLoading && (
            <Loader
              title='Loading...'
              size={50}
              center
            />
          )}

          {error && (
            <Message
              title='Error'
              variant='danger'
            >
              {error?.data?.message || error?.error || "Something went wrong."}
            </Message>
          )}

          {!isLoading && !error && (
            <>
              <h3 className='profile-username text-center'>{title}</h3>
              <ul className='list-group list-group-unbordered'>
                <li className='list-group-item'>
                  <b>ID</b> <span className='pull-right'>{todaId}</span>
                </li>
                <li className='list-group-item'>
                  <b>Description</b>{" "}
                  <span className='pull-right'>{description}</span>
                </li>
                <li className='list-group-item'>
                  <b>Address</b>{" "}
                  <span className='pull-right'>{locationDetails?.address}</span>
                </li>
                <li className='list-group-item'>
                  <b>Created By</b>{" "}
                  <span className='pull-right'>{createdBy}</span>
                </li>
                <li className='list-group-item'>
                  <b>Created At</b>{" "}
                  <span className='pull-right'>{formatIsoDate(createdAt)}</span>
                </li>
                <li className='list-group-item'>
                  <b>Location</b>{" "}
                  <NewLocationMap
                    containerStyle={{
                      width: "100%",
                      height: "200px",
                    }}
                    latitude={locationDetails?.latitude}
                    longitude={locationDetails?.longitude}
                  />
                </li>

                <li className='list-group-item'>
                  <Link
                    to={`/todas/${todaId}/edit`}
                    className='btn btn-primary btn-md center-block'
                  >
                    <i className='fa fa-edit'></i> Update Toda
                  </Link>
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TodaDetails;
