import { useSelector } from "react-redux";
import { selectUserInfo } from "../../state/slices/authSlice";
import { Link } from "react-router-dom";
import Message from "../../components/common/Message";
import Loader from "../../components/common/loaders/Loader";
import { formatIsoDate } from "../../utils/utils";
import { useGetShopperGroupMembersByIdQuery } from "../../state/slices/shopperGroupsApiSlice";

const ShopperGroupMembers = ({ shopperGroupId }) => {
  const { accessToken } = useSelector(selectUserInfo);
  const { data, isLoading, error } = useGetShopperGroupMembersByIdQuery({
    shopperGroupId,
    accessToken,
  });

  const shoppers = data || [];

  return (
    <div className='box box-solid'>
      <div className='box-header'>
        <h3 className='box-title'>
          <i className='fa fa-users'></i> Shopper Group Members
        </h3>

        <div className='box-tools'>
          <Link
            to={`/shopper-groups/${shopperGroupId}/manage-members`}
            className='btn btn-md btn-primary'
          >
            <i className='fa fa-edit'></i> Manage members
          </Link>
        </div>
      </div>
      <div className='box-body table-responsive no-padding'>
        {isLoading && (
          <Loader
            title='Loading...'
            size={50}
            center
          />
        )}

        {error && (
          <Message
            title='Error'
            variant='danger'
          >
            {error.data.message || error.error}
          </Message>
        )}

        {!isLoading && !error && (
          <>
            <table className='table table-hover'>
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Email</th>

                  <th>Phone Number</th>
                  <th>Date Created</th>
                </tr>

                {shoppers.length === 0 ? (
                  <tr>
                    <td
                      colSpan={5}
                      align='center'
                    >
                      <strong>NO MEMBERS FOUND.</strong>
                    </td>
                  </tr>
                ) : (
                  shoppers.map(shopper => {
                    const { id, firstName, lastName, email, phone, createdAt } =
                      shopper;

                    return (
                      <tr key={id}>
                        <td>
                          {firstName} {lastName}
                        </td>
                        <td>{email}</td>
                        <td>+63{phone}</td>
                        <td>{formatIsoDate(createdAt)}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

export default ShopperGroupMembers;
