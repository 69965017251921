import { useState } from "react";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../state/slices/authSlice";

import { Link } from "react-router-dom";
import BalanceRequestType from "../../components/common/BalanceRequestType";
import Modal from "../../components/common/Modal";
import ModalHeader from "../../components/common/ModalHeader";
import ModalBody from "../../components/common/ModalBody";
import ModalFooter from "../../components/common/ModalFooter";

import { useUpdateBalanceRequestMutation } from "../../state/slices/balanceRequestsApiSlice";

import { formatCurrency, formatIsoDate } from "../../utils/utils";
import { toast } from "react-toastify";

const BalanceRequestDetails = ({ balanceRequest, onSubmit }) => {
  const {
    id,
    type,
    amount,
    // walletId,
    status,
    reason,
    note,
    requestedBy,
    userType,
    reviewedBy,
    reviewedAt,
    createdAt,
    // updatedAt,
  } = balanceRequest || {};

  const {
    accessToken,
    id: adminId,
    firstName,
    lastName,
    phone,
  } = useSelector(selectUserInfo);

  const [selectedStatus, setSelectedStatus] = useState("");
  const [enteredReason, setEnteredReason] = useState("");

  const [updateBalanceRequest, { isLoading }] =
    useUpdateBalanceRequestMutation();

  const _submitForm = async e => {
    if (selectedStatus === "") {
      toast.error("Please select a status");
      return;
    }

    if (selectedStatus === "rejected" && enteredReason === "") {
      toast.error("Please provide a reason");
      return;
    }

    console.log("submitted");
    console.log(selectedStatus, enteredReason);

    try {
      const updatedFields = {
        id,
        status: selectedStatus,
        reason: enteredReason,
        reviewedBy: {
          id: adminId,
          firstName: firstName,
          lastName: lastName,
          phone: phone,
        },
      };

      await updateBalanceRequest({
        updatedFields,
        accessToken,
      }).unwrap();

      setEnteredReason("");
      setSelectedStatus("");

      onSubmit();

      toast.success("Balance request updated successfully");
    } catch (e) {
      console.log(e);
      if (e.data.errors) {
        toast.error(e.data.errors[0].message);
      } else if (e.data.message) {
        toast.error(e.data.message);
      } else {
        toast.error("Something went wrong, please try again.");
      }
    }
  };

  return (
    <>
      <div className='box box-solid box-widget'>
        <div className='box-header with-border'>
          <h3 className='box-title'>Balance Request Details &mdash; {id}</h3>
        </div>
        <div className='box-body  table-responsive '>
          <table className='table no-margin'>
            <tbody>
              <tr>
                <th
                  style={{
                    width: "50%",
                  }}
                  className='no-border-top'
                >
                  Requested By:
                </th>
                <td className='no-border-top'>
                  <Link to={`/users/${requestedBy.id}`}>
                    {requestedBy.firstName} {requestedBy.lastName}
                  </Link>{" "}
                  ({userType.toUpperCase()})
                </td>
              </tr>
              <tr>
                <th>Note:</th>
                <td>{note}</td>
              </tr>
              <tr>
                <th>Amount:</th>
                <td>{formatCurrency(amount)}</td>
              </tr>
              <tr>
                <th>Type:</th>
                <td>{type.toUpperCase()}</td>
              </tr>
              <tr>
                <th>Status:</th>
                <td>
                  <BalanceRequestType value={status} />
                </td>
              </tr>
              {reason && (
                <tr>
                  <th>Reason:</th>
                  <td>{reason}</td>
                </tr>
              )}
              <tr>
                <th>Requested Date:</th>
                <td> {formatIsoDate(createdAt)}</td>
              </tr>
              {reviewedBy && (
                <tr>
                  <th>Reviewed By:</th>
                  <td>
                    {reviewedBy.firstName} {reviewedBy.lastName}
                  </td>
                </tr>
              )}

              {reviewedAt && (
                <tr>
                  <th>Reviewed At:</th>
                  <td> {formatIsoDate(reviewedAt)}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='box-footer'>
          {status === "pending" && (
            <button
              className='btn btn-success btn-md'
              data-toggle='modal'
              data-target='#review-request'
            >
              Review Request
            </button>
          )}
        </div>
      </div>

      <Modal id='review-request'>
        <ModalHeader title='Add Member' />
        <ModalBody>
          <form>
            <div className='form-group'>
              <label htmlFor='selectedStatus'>Status</label>

              <select
                name='selectedStatus'
                id='selectedStatus'
                className='form-control'
                value={selectedStatus}
                onChange={e => setSelectedStatus(e.target.value)}
              >
                <option value=''>Select status</option>
                <option value='approved'>Approve</option>
                <option value='rejected'>Reject</option>
              </select>
            </div>

            {selectedStatus === "rejected" && (
              <div className='form-group'>
                <label htmlFor='enteredReason'>Reason</label>
                <textarea
                  name='enteredReason'
                  id='enteredReason'
                  className='form-control'
                  value={enteredReason}
                  onChange={e => setEnteredReason(e.target.value)}
                ></textarea>
              </div>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <button
            type='button'
            className='btn btn-success '
            data-dismiss='modal'
            onClick={e => _submitForm(e)}
          >
            Submit
          </button>

          <button
            type='button'
            className='btn btn-danger pull-right'
            data-dismiss='modal'
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default BalanceRequestDetails;
