import React, { useEffect } from "react";
import ContentHeader from "../components/layouts/ContentHeader";
import MainContent from "../components/layouts/MainContent";
import { Link, useSearchParams } from "react-router-dom";
import { useGetRideRequestsQuery } from "../state/slices/rideRequestsApiSlice";
import { formatIsoDate } from "../utils/utils";
import Message from "../components/common/Message";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../state/slices/authSlice";
import Loader from "../components/common/loaders/Loader";
import RideRequestStatus from "../components/common/RideRequestStatus";

const RideRequestsPage = () => {
  const { accessToken } = useSelector(selectUserInfo);

  const [searchParams, setSearchParams] = useSearchParams();

  const after = searchParams.get("after") || null;
  const before = searchParams.get("before") || null;
  const limit = searchParams.get("limit") || 10;
  const view = searchParams.get("view") || "dateRequested";
  const order = searchParams.get("order") || "desc";

  useEffect(() => {
    const paramsToSet = { limit, view, order };

    if (after) {
      paramsToSet.after = after;
    }

    if (before) {
      paramsToSet.before = before;
    }

    setSearchParams(paramsToSet);
  }, [setSearchParams, limit, view, before, after, order]);

  const { data, isLoading, error } = useGetRideRequestsQuery({
    accessToken,
    after,
    before,
    limit,
    view,
    order,
  });

  const { rideRequests, pagination } = data || {};
  const { prev, next } = pagination || {};

  return (
    <>
      <ContentHeader pageTitle='Ride Requests'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/rides'>
              <i className='fa fa-list'></i> Rides
            </Link>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        <div className='box box-solid'>
          <div className='box-header'>
            <div className='box-tools'>
              <div
                className='input-group input-group-sm hidden-xs'
                style={{ width: "150px" }}
              >
                <input
                  type='text'
                  name='table_search'
                  className='form-control pull-right'
                  placeholder='Search'
                />

                <div className='input-group-btn'>
                  <button
                    type='submit'
                    className='btn btn-default'
                  >
                    <i className='fa fa-search'></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='box-body table-responsive no-padding'>
            {isLoading && (
              <Loader
                title='Loading...'
                size={50}
                center
              />
            )}

            {error && (
              <Message
                title='Error'
                variant='danger'
              >
                {error.data.message || error.error}
              </Message>
            )}

            {!isLoading && !error && (
              <table className='table table-hover'>
                <tbody>
                  <tr>
                    <th>Date Requested</th>
                    <th>ID</th>
                    <th>Origin Location</th>
                    <th>Destination Location</th>
                    <th>Total Passenger</th>
                    <th>Status</th>
                    <th>Passenger Name</th>
                    <th>Driver Name</th>
                  </tr>

                  {rideRequests.map(rideRequest => {
                    const {
                      rideRequestId,
                      originLocationDetails,
                      destinationLocationDetails,
                      status,
                      totalPassenger,
                      passengerUserDetails,
                      driverUserDetails,
                      dateRequested,
                      dateAccepted,
                    } = rideRequest;

                    const passengerId = passengerUserDetails?.id;
                    const passengerName = `${passengerUserDetails?.firstName} ${passengerUserDetails?.lastName}`;
                    const driverId = passengerUserDetails?.id;
                    const driverName = `${driverUserDetails?.firstName} ${driverUserDetails?.lastName}`;

                    const originAddress = originLocationDetails?.address;
                    const destinationAddress =
                      destinationLocationDetails?.address;

                    return (
                      <tr key={rideRequestId}>
                        <td>{formatIsoDate(dateRequested)}</td>

                        <td>
                          <Link to={`/ride-requests/${rideRequestId}`}>
                            {rideRequestId}
                          </Link>
                        </td>
                        <td>{originAddress}</td>
                        <td>{destinationAddress}</td>
                        <td>{totalPassenger}</td>
                        <td>
                          <RideRequestStatus status={status} />
                        </td>
                        <td>
                          <Link to={`/users/${passengerId}`}>
                            {passengerName}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/users/${driverId}`}>{driverName}</Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>

          <div className='box-footer clearfix'>
            {!isLoading && !error && (
              <ul className='pagination pagination-lg no-margin pull-right'>
                <li>
                  <button
                    className='btn btn-md'
                    disabled={!prev}
                    role='link'
                    aria-disabled='true'
                    onClick={() => {
                      setSearchParams({
                        before: prev,
                        limit,
                        order,
                        view,
                      });
                    }}
                  >
                    «
                  </button>
                </li>
                <li>
                  <button
                    className='btn btn-md btn-disabled'
                    disabled={!next}
                    onClick={() => {
                      setSearchParams({ after: next, limit, order, view });
                    }}
                  >
                    »
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default RideRequestsPage;
